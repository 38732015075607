!function (e) {
  function n(e, n) {
    return e.replace(/<<(\d+)>>/g, function (e, s) {
      return "(?:" + n[+s] + ")";
    });
  }

  function s(e, s, a) {
    return RegExp(n(e, s), a || "");
  }

  function a(e, n) {
    for (var s = 0; s < n; s++) e = e.replace(/<<self>>/g, function () {
      return "(?:" + e + ")";
    });

    return e.replace(/<<self>>/g, "[^\\s\\S]");
  }

  var t = "bool byte char decimal double dynamic float int long object sbyte short string uint ulong ushort var void",
      r = "class enum interface record struct",
      i = "add alias and ascending async await by descending from(?=\\s*(?:\\w|$)) get global group into init(?=\\s*;) join let nameof not notnull on or orderby partial remove select set unmanaged value when where with(?=\\s*{)",
      o = "abstract as base break case catch checked const continue default delegate do else event explicit extern finally fixed for foreach goto if implicit in internal is lock namespace new null operator out override params private protected public readonly ref return sealed sizeof stackalloc static switch this throw try typeof unchecked unsafe using virtual volatile while yield";

  function l(e) {
    return "\\b(?:" + e.trim().replace(/ /g, "|") + ")\\b";
  }

  var d = l(r),
      p = RegExp(l(t + " " + r + " " + i + " " + o)),
      c = l(r + " " + i + " " + o),
      u = l(t + " " + r + " " + o),
      g = a("<(?:[^<>;=+\\-*/%&|^]|<<self>>)*>", 2),
      b = a("\\((?:[^()]|<<self>>)*\\)", 2),
      h = "@?\\b[A-Za-z_]\\w*\\b",
      f = n("<<0>>(?:\\s*<<1>>)?", [h, g]),
      m = n("(?!<<0>>)<<1>>(?:\\s*\\.\\s*<<1>>)*", [c, f]),
      k = "\\[\\s*(?:,\\s*)*\\]",
      y = n("<<0>>(?:\\s*(?:\\?\\s*)?<<1>>)*(?:\\s*\\?)?", [m, k]),
      w = n("[^,()<>[\\];=+\\-*/%&|^]|<<0>>|<<1>>|<<2>>", [g, b, k]),
      v = n("\\(<<0>>+(?:,<<0>>+)+\\)", [w]),
      x = n("(?:<<0>>|<<1>>)(?:\\s*(?:\\?\\s*)?<<2>>)*(?:\\s*\\?)?", [v, m, k]),
      $ = {
    keyword: p,
    punctuation: /[<>()?,.:[\]]/
  },
      _ = "'(?:[^\r\n'\\\\]|\\\\.|\\\\[Uux][\\da-fA-F]{1,8})'",
      B = "\"(?:\\\\.|[^\\\\\"\r\n])*\"";
  e.languages.csharp = e.languages.extend("clike", {
    string: [{
      pattern: s("(^|[^$\\\\])<<0>>", ["@\"(?:\"\"|\\\\[^]|[^\\\\\"])*\"(?!\")"]),
      lookbehind: !0,
      greedy: !0
    }, {
      pattern: s("(^|[^@$\\\\])<<0>>", [B]),
      lookbehind: !0,
      greedy: !0
    }],
    "class-name": [{
      pattern: s("(\\busing\\s+static\\s+)<<0>>(?=\\s*;)", [m]),
      lookbehind: !0,
      inside: $
    }, {
      pattern: s("(\\busing\\s+<<0>>\\s*=\\s*)<<1>>(?=\\s*;)", [h, x]),
      lookbehind: !0,
      inside: $
    }, {
      pattern: s("(\\busing\\s+)<<0>>(?=\\s*=)", [h]),
      lookbehind: !0
    }, {
      pattern: s("(\\b<<0>>\\s+)<<1>>", [d, f]),
      lookbehind: !0,
      inside: $
    }, {
      pattern: s("(\\bcatch\\s*\\(\\s*)<<0>>", [m]),
      lookbehind: !0,
      inside: $
    }, {
      pattern: s("(\\bwhere\\s+)<<0>>", [h]),
      lookbehind: !0
    }, {
      pattern: s("(\\b(?:is(?:\\s+not)?|as)\\s+)<<0>>", [y]),
      lookbehind: !0,
      inside: $
    }, {
      pattern: s("\\b<<0>>(?=\\s+(?!<<1>>|with\\s*\\{)<<2>>(?:\\s*[=,;:{)\\]]|\\s+(?:in|when)\\b))", [x, u, h]),
      inside: $
    }],
    keyword: p,
    number: /(?:\b0(?:x[\da-f_]*[\da-f]|b[01_]*[01])|(?:\B\.\d+(?:_+\d+)*|\b\d+(?:_+\d+)*(?:\.\d+(?:_+\d+)*)?)(?:e[-+]?\d+(?:_+\d+)*)?)(?:[dflmu]|lu|ul)?\b/i,
    operator: />>=?|<<=?|[-=]>|([-+&|])\1|~|\?\?=?|[-+*/%&|^!=<>]=?/,
    punctuation: /\?\.?|::|[{}[\];(),.:]/
  }), e.languages.insertBefore("csharp", "number", {
    range: {
      pattern: /\.\./,
      alias: "operator"
    }
  }), e.languages.insertBefore("csharp", "punctuation", {
    "named-parameter": {
      pattern: s("([(,]\\s*)<<0>>(?=\\s*:)", [h]),
      lookbehind: !0,
      alias: "punctuation"
    }
  }), e.languages.insertBefore("csharp", "class-name", {
    namespace: {
      pattern: s("(\\b(?:namespace|using)\\s+)<<0>>(?:\\s*\\.\\s*<<0>>)*(?=\\s*[;{])", [h]),
      lookbehind: !0,
      inside: {
        punctuation: /\./
      }
    },
    "type-expression": {
      pattern: s("(\\b(?:default|sizeof|typeof)\\s*\\(\\s*(?!\\s))(?:[^()\\s]|\\s(?!\\s)|<<0>>)*(?=\\s*\\))", [b]),
      lookbehind: !0,
      alias: "class-name",
      inside: $
    },
    "return-type": {
      pattern: s("<<0>>(?=\\s+(?:<<1>>\\s*(?:=>|[({]|\\.\\s*this\\s*\\[)|this\\s*\\[))", [x, m]),
      inside: $,
      alias: "class-name"
    },
    "constructor-invocation": {
      pattern: s("(\\bnew\\s+)<<0>>(?=\\s*[[({])", [x]),
      lookbehind: !0,
      inside: $,
      alias: "class-name"
    },
    "generic-method": {
      pattern: s("<<0>>\\s*<<1>>(?=\\s*\\()", [h, g]),
      inside: {
        function: s("^<<0>>", [h]),
        generic: {
          pattern: RegExp(g),
          alias: "class-name",
          inside: $
        }
      }
    },
    "type-list": {
      pattern: s("\\b((?:<<0>>\\s+<<1>>|record\\s+<<1>>\\s*<<5>>|where\\s+<<2>>)\\s*:\\s*)(?:<<3>>|<<4>>|<<1>>\\s*<<5>>|<<6>>)(?:\\s*,\\s*(?:<<3>>|<<4>>|<<6>>))*(?=\\s*(?:where|[{;]|=>|$))", [d, f, h, x, p.source, b, "\\bnew\\s*\\(\\s*\\)"]),
      lookbehind: !0,
      inside: {
        "record-arguments": {
          pattern: s("(^(?!new\\s*\\()<<0>>\\s*)<<1>>", [f, b]),
          lookbehind: !0,
          greedy: !0,
          inside: e.languages.csharp
        },
        keyword: p,
        "class-name": {
          pattern: RegExp(x),
          greedy: !0,
          inside: $
        },
        punctuation: /[,()]/
      }
    },
    preprocessor: {
      pattern: /(^[\t ]*)#.*/m,
      lookbehind: !0,
      alias: "property",
      inside: {
        directive: {
          pattern: /(#)\b(?:define|elif|else|endif|endregion|error|if|line|nullable|pragma|region|undef|warning)\b/,
          lookbehind: !0,
          alias: "keyword"
        }
      }
    }
  });
  var E = B + "|" + _,
      R = n("/(?![*/])|//[^\r\n]*[\r\n]|/\\*(?:[^*]|\\*(?!/))*\\*/|<<0>>", [E]),
      z = a(n("[^\"'/()]|<<0>>|\\(<<self>>*\\)", [R]), 2),
      S = "\\b(?:assembly|event|field|method|module|param|property|return|type)\\b",
      j = n("<<0>>(?:\\s*\\(<<1>>*\\))?", [m, z]);
  e.languages.insertBefore("csharp", "class-name", {
    attribute: {
      pattern: s("((?:^|[^\\s\\w>)?])\\s*\\[\\s*)(?:<<0>>\\s*:\\s*)?<<1>>(?:\\s*,\\s*<<1>>)*(?=\\s*\\])", [S, j]),
      lookbehind: !0,
      greedy: !0,
      inside: {
        target: {
          pattern: s("^<<0>>(?=\\s*:)", [S]),
          alias: "keyword"
        },
        "attribute-arguments": {
          pattern: s("\\(<<0>>*\\)", [z]),
          inside: e.languages.csharp
        },
        "class-name": {
          pattern: RegExp(m),
          inside: {
            punctuation: /\./
          }
        },
        punctuation: /[:,]/
      }
    }
  });
  var A = ":[^}\r\n]+",
      F = a(n("[^\"'/()]|<<0>>|\\(<<self>>*\\)", [R]), 2),
      P = n("\\{(?!\\{)(?:(?![}:])<<0>>)*<<1>>?\\}", [F, A]),
      U = a(n("[^\"'/()]|/(?!\\*)|/\\*(?:[^*]|\\*(?!/))*\\*/|<<0>>|\\(<<self>>*\\)", [E]), 2),
      Z = n("\\{(?!\\{)(?:(?![}:])<<0>>)*<<1>>?\\}", [U, A]);

  function q(n, a) {
    return {
      interpolation: {
        pattern: s("((?:^|[^{])(?:\\{\\{)*)<<0>>", [n]),
        lookbehind: !0,
        inside: {
          "format-string": {
            pattern: s("(^\\{(?:(?![}:])<<0>>)*)<<1>>(?=\\}$)", [a, A]),
            lookbehind: !0,
            inside: {
              punctuation: /^:/
            }
          },
          punctuation: /^\{|\}$/,
          expression: {
            pattern: /[\s\S]+/,
            alias: "language-csharp",
            inside: e.languages.csharp
          }
        }
      },
      string: /[\s\S]+/
    };
  }

  e.languages.insertBefore("csharp", "string", {
    "interpolation-string": [{
      pattern: s("(^|[^\\\\])(?:\\$@|@\\$)\"(?:\"\"|\\\\[^]|\\{\\{|<<0>>|[^\\\\{\"])*\"", [P]),
      lookbehind: !0,
      greedy: !0,
      inside: q(P, F)
    }, {
      pattern: s("(^|[^@\\\\])\\$\"(?:\\\\.|\\{\\{|<<0>>|[^\\\\\"{])*\"", [Z]),
      lookbehind: !0,
      greedy: !0,
      inside: q(Z, U)
    }],
    char: {
      pattern: RegExp(_),
      greedy: !0
    }
  }), e.languages.dotnet = e.languages.cs = e.languages.csharp;
}(Prism);
export default {};